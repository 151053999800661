/* eslint-disable jsx-a11y/iframe-has-title */
import Tittle from '../components/Tittle';
import NavBarClose from '../components/NavBarClose';
import NavBarFooter from '../components/NavBarFooter';
import '../styles/Comunicados.css';
import GoToTop from '../components/GoToTop';

import {
  Acuerdos, Objecion, Rol, Transporte, Lentes, Telefonos, ConvConsejo, ConvCongreso, AcuerdosFtse, HaciendaTab, LaJornada2407, LineamientosCredencial,
  Escalafon2023, RemAñosServ23, PremioAntig23, ComCondEsca, UniformTec23, ExcursionNov2023, ConvCalyOfre23, ComImposicion, Condi2023, Cala23, ConvCala2023,
  OficioReyes23, FloresOct23, SolCempa, EsquelaNov23, CarreraNOv23, FloresRan, FloresAzafran, SeparacionSindicalizados, SolHerenciaPlazas, RespHerenciaPlazas,
  SolicStand, SinAdornoElect, Diciembre25, Enero1o, RolVacacional2024, Febrero05, OficioPeticionJoel2024, ExcursionTlacotlapico, DistribucionPlazas2024,
  NuevosCostosAcapulco, ObituarioFeb2024, RespuestaAmparoCGT, Marzo18, RiesgoTrabajo, ExcursionAcapulco, ConvocatoriaSecretario, Asamblea, CambioAseguradora,
  Agroasemex, SemSalud, Mayo1o, EsquelaSaraMontiel, AcuerdosFSTSE2024, ConvocatoriaCongreso2024, FormatoPonencias2024,
} from '../images/ImagesHomeComunicados';

const Comunicados = () => {
  return (
    <>
      <header className='setHeading'>
        <Tittle />
        <NavBarClose />
      </header>
      <main className='setMain'>
        <section className='containerReleases'>
          <article className='miniReleases'>

            <div className='release'>
              <a href='https://drive.google.com/file/d/1xdxnab7H3oooB0ZiEKkMjoI4sPLIMEaD' target="blank"><FormatoPonencias2024 /></a>
              <p className='titleRelease'>Formato de Ponencia</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1QNXffIGNCcqSYHUTtqHvaM1Nj0R2kTAA' target="blank"><ConvocatoriaCongreso2024 /></a>
              <p className='titleRelease'>Convocatoria al XV Congreso Nacional Ordinario de representantes del Sindicato Nacional de Trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1rrWJTMTT84DAOrsHPptZQCumkRUMi2I6' target="blank"><AcuerdosFSTSE2024 /></a>
              <p className='titleRelease'>Acuerdos signados entre la Secretaría de Hacienda y Crédito Público y la Federación de Sindicatos de Trabajadores al Servicio del Estado 2024</p>
            </div>

            <div className='release'>
              <a href='https://drive.google.com/file/d/19jk4n4SJrgo_SdhOE41zGBsIPlIQvxZd' target="blank"><EsquelaSaraMontiel /></a>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1EV-ZWkaMFOsO3BycLMz_sI-ilUvNf4ma' target="blank"><Mayo1o /></a>
              <p className='titleRelease'>Miércoles 1° de mayo de 2024 <br />(día inhábil)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/159vN_k4YgCuXCjMDElpG4JCjl6GU30u-' target="blank"><SemSalud /></a>
              <p className='titleRelease'>Jornada de la salud en las instalaciones del Comité <br />de 09 am a 04 pm  <br />1a sem. 13 al 17 de mayo <br />2a sem. 20 al 24 de mayo </p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1B-ezVMw1QDvYP7isAqNuRoCR7t0ANLLB' target="blank"><Agroasemex /></a>
              <p className='titleRelease'>Folleto Informativo Seguro de Retiro AGROASEMEX</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1vTSfY6iXy_s0HkGpK4hB2Kh1YiHhdCc_' target="blank"><CambioAseguradora /></a>
              <p className='titleRelease'>Adjudicación del Seguro para el Retiro AGROASEMEX S.A.,vigente a partir del 1°/03/2024 a 28/02/2026</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1DW34Y1xHmsVqSxCpgAZ1IJSlCADqe7ec' target="blank"><ConvocatoriaSecretario /></a>
              <p className='titleRelease'>Convocatoria para el registro de candidatos(as) a Secretario(a) General del SNTSEDATU</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1_mcPg6PwOqMJY54imaZ-w-cV_7CO8dsY' target="blank"><ExcursionAcapulco /></a>
              <p className='titleRelease'>Convocatoria a la excursión al puerto de Acapulco de Juárez en el Estado de Guerrero, del 14 al 18 de junio de 2024</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1zJ7DQvc0DaNMiCwtA53eKfrhhJLlp9-z' target="blank"><RiesgoTrabajo /></a>
              <p className='titleRelease'>Trámite que debes realizar ante el ISSSTE si presentas un riesgo de trabajo</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/18GSzHk-gUeAs3skUCxq8YxzPy9gOBdd0' target="blank"><Marzo18 /></a>
              <p className='titleRelease'>Lunes 18 de marzo de 2024 <br />(día inhábil)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1s5HykaUT5B4ijRhKYfcZ3qn0o6GexJMH' target="blank"><RespuestaAmparoCGT /></a>
              <p className='titleRelease'>Respuesta al amparo interpuesto por el Sindicato Nacional de Trabajadores de la Sedatu ante el Tribunal Federal de Conciliación y Arbitraje <br /> (29 de febrero de 2024)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1UDsVTqaJhpETaF5OHamO-DWiNZ8fHol7' target="blank"><ObituarioFeb2024 /></a>
              <p className='titleRelease'></p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1LkgQvpMZgqTFPY666PT-KI1-ibhVslFD' target="blank"><NuevosCostosAcapulco /></a>
              <p className='titleRelease'>Nuevos costos para la casa de descanso "Sergio Rubio Ruiz"</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/165dFKXOqRHIe0ivIYvMjkf3gw3OKWyFt' target="blank"><DistribucionPlazas2024 /></a>
              <p className='titleRelease'>Distribucion de las plazas correspondientes al Sindicato Nacional de Trabajadores de la SEDATU</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1A6bgG1IJS6PS0AAh8mQzKdeeGlf2owR8' target="blank"><ExcursionTlacotlapico /></a>
              <p className='titleRelease'>Convocatoria a la excursión al parque acuatico de Tlacotlapico en el Estado de Hidalgo el 09 de marzo de 2024</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1ZEkTfTmgZHE7LHRYJpkIHQGV-dIWn0JP' target="blank"><OficioPeticionJoel2024 /></a>
              <p className='titleRelease'>Propuesta de incremento dirigido a Presidecia por parte de Joel Ayala, Presidente de la FSTSE <br />(01 febrero 2024)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1Bjg89JdXQVcBkpY0y12oLnzKEbOreN-U' target="blank"><Febrero05 /></a>
              <p className='titleRelease'>Lunes 05 de febrero de 2024 <br />(día inhábil)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1k_6_oMtHpSA-BpDDJ8cG8qFZmuEKNnOQ' target="blank"><RolVacacional2024 /></a>
              <p className='titleRelease'>Rol Vacacional 2024</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1mj6Si2QgOoVKuLqLP8NWwgv0NdDFnz4D' target="blank"><Enero1o /></a>
              <p className='titleRelease'>Lunes 01 de enero de 2024 <br />(día inhábil)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1XYViiOIeKcn1-X5NYwRl1NZ9Lptqr2QJ' target="blank"><Diciembre25 /></a>
              <p className='titleRelease'>Lunes 25 de diciembre de 2023 <br />(día inhábil)</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1CSIWJK4EEV0JP1eS29aD18rbj6rHJ9FX' target="blank"><SinAdornoElect /></a>
              <p className='titleRelease'>Invitación a decorar los espacios de oficina sin series y/o adornos eléctricos</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1byL4MZRd59lWQdvFULLAZUN1mJw3n5VO' target="blank"><SolicStand /></a>
              <p className='titleRelease'>Respuesta a la solicitud de espacio para el montaje de stand de PENSIONISSSTE</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1BjdGUPZashgn69T3GQxF572c9JsUhaxN' target="blank"><RespHerenciaPlazas /></a>
              <p className='titleRelease'>Respuesta a la solicitud de acuerdo para llevar a cabo el programa para que los trabajadores y las trabajadoras en condiciones de jubilación puedan ingresar a sus hijos</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1PjmaZDquXljAvY1f0BvfchPK49l5tLkL' target="blank"><SolHerenciaPlazas /></a>
              <p className='titleRelease'>Solicitud de acuerdo para llevar a cabo el programa para que los trabajadores y las trabajadoras en condiciones de jubilación puedan ingresar a sus hijos</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1iRj5CHVAqNptVN-lDMreI0k5feW4EoWw' target="blank"><SeparacionSindicalizados /></a>
              <p className='titleRelease'>Solicitud de separación de sindicalizados y documentación base que sustenta dicha petición</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1SJbGcqKjTxmHSRmtcSKzTx-AUGKuIGe1' target="blank"><FloresRan /></a>
              <p className='titleRelease'>Invitación a la venta directa de flores de nochebuena en el Registro Agrario Nacional</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1SHlYmKshd1ljGDWJPMP1t-8ffpK8so8g' target="blank"><FloresAzafran /></a>
              <p className='titleRelease'>Invitación a la venta directa de flores de nochebuena en Azafrán y Módulos</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1Cyq1NezxYP85r2v2R9qskFReV4Q1sQwQ' target="blank"><CarreraNOv23 /></a>
              <p className='titleRelease'>Convocatoria a la carrera conmemorativa al 20 de noviembre que se llevara a cabo el sábado 25 de noviembre de 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1kHN6BlMP3ILirJOSS6NteSCZ_RCkoAZp' target="blank"><EsquelaNov23 /></a>
              <p className='titleRelease'></p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/12Eq9v7kR_kACABa0elWFVi4nquBwGkYu' target="blank"><SolCempa /></a>
              <p className='titleRelease'>Invitación a la venta directa de flores de Cempasúchil</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1Dp_gobTOA2UXPiI3C6aRiO7iH-vldr4O' target="blank"><FloresOct23 /></a>
              <p className='titleRelease'>Invitación a la venta directa de flores de Cempasúchil</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1rRu5J6oLDwKC08eb6B4vgyX4iy2dhLHc' target="blank"><OficioReyes23 /></a>
              <p className='titleRelease'>Actualización de hijos de trabajadoras y trabajadores para la prestación de Día de Reyes</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1yrIGR58GKIZ_rNoVkgy-6xSbPe1CcnlC' target="blank"><ConvCala2023 /></a>
              <p className='titleRelease'>Convocatoria para el Concurso de Ofrendas y Calaveritas Literarias 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/18KwfDhfJYcG7uk8tEhQCQvVM0myN7xi6' target="blank"><Cala23 /></a>
              <p className='titleRelease'>Se informa sobre la emisión de Convocatoria de Calaveritas y Ofrendas 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1bw1vhYaJJ0XbWCdX7bhrKrAeZfrlzFQW' target="blank"><Condi2023 /></a>
              <p className='titleRelease'>Comunicado sobre las nuevas Condiciones Generales de Trabajo vigentes a partir del 31 de agosto de 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1yEwvv4v33cSHVXVPCxrj8zGTdTOXYu_z' target="blank"><ComImposicion /></a>
              <p className='titleRelease'>Comunicado informativo sobre la imposición de las Condiciones Generales de Trabajo por parte de las autoridades</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1ERJyPnAfMHBD7Sjt1B-y2iyCHbVkpq8x' target="blank"><ConvCalyOfre23 /></a>
              <p className='titleRelease'>Solicitud de emisión para Convocatoria de Calaveritas Literarias y Ofrendas </p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1dfk3kiZQR3AYuRHxrajXz9dkQbSooTeq' target="blank"><ExcursionNov2023 /></a>
              <p className='titleRelease'>Convocatoria a la excursión a Chignahuapan el 4 de noviembre de 2023</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1JneGBXd5-yJKYhxPwhE1BiRnORAzEQ2B' target="blank"><UniformTec23 /></a>
              <p className='titleRelease'>Estatus de entrega de Uniformes Técnicos ejercicio 2023.</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1rr95z0RyGl4rhnKPXSeLid32XBatFWOM' target="blank"><ComCondEsca /></a>
              <p className='titleRelease'>Comunicado sobre los movimientos escalafonarios y actualizaciones a las Condiciones Generales de Trabajo</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1ZoIaW7o0r94ke7QX6f4Be22jLtA0FInZ' target="blank"><PremioAntig23 /></a>
              <p className='titleRelease'>Convocatoria para el Premio Nacional de Antiguedad en el Servicio Publico 2023.</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1JmjhpDXvAUQfbX7r6aQzU3rlFItOhe5s' target="blank"><RemAñosServ23 /></a>
              <p className='titleRelease'>Oficio y Formato para la solicitud de remuneración por Años de Servicio cumplidos en 2023.</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1EkOA9E4dG5tqIb8C_3hbPv1S5se6CzWc' target="blank"><Escalafon2023 /></a>
              <p className='titleRelease'>Movimiento escalafonario 2023.</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1la9tszwEoMAvTECFvIoAyf19ZKM5oG7G' target="blank"><LineamientosCredencial /></a>
              <p className='titleRelease'>Lineamientos para el uso y portación de la credencial de identificación institucional</p>
            </div>
            <div className='release' >
              <a href='https://www.jornada.com.mx/notas/2023/07/24/politica/usara-el-issste-reserva-financiera-ante-deficit-en-su-seguro-de-salud/' target="blank"><LaJornada2407 /></a>
              <p className='titleRelease'>Noticia publicada el 24 de julio de 2023</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1WinEQX0sPh4019uybiBEYcFlqpR4n0AK' target="blank"><HaciendaTab /></a>
              <p className='titleRelease'>Incremento a Tabuladores de Sueldos y  Salarios y al de Prestaciones</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1W5tc2jub6SDzs7jvdDNNnUG5vHPnUBMh' target="blank"><AcuerdosFtse /></a>
              <p className='titleRelease'>Acuerdos FTSE</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1USn6eAJs3WJqOfR9oH2zrY7FH4Q_Kgu1' target="blank"><ConvCongreso /></a>
              <p className='titleRelease'>XVIII Congreso Nacional Extraordinario</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1UPDF3mEPZKniEI9JIxD4eovIaX0HNraG' target="blank"><ConvConsejo /></a>
              <p className='titleRelease'>XXXIII Consejo Nacional Ordinario</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1KLKR0rhTm8pdIi1fUGQFLv-ALuqepfLS/view?usp=sharing' target="blank"><Lentes /></a>
              <p className='titleRelease'>Lineamientos Lentes</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1HUnWRzpkw5oRsBRJgo8oRYl7NGuDGoA6/view?usp=drive_link' target="blank"><Transporte /></a>
              <p className='titleRelease'>Transporte 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1D-BE-SzL77SWOt1xf86HquFGZmeaQmEb/view?usp=drive_link' target='blank'><Rol /></a>
              <p className='titleRelease'>Rol Vacacional 2023</p>
            </div>
            <div className='release'>
              <a href='https://drive.google.com/file/d/1D-BE-SzL77SWOt1xf86HquFGZmeaQmEb/view?usp=drive_link' target='blank'><Telefonos /></a>
              <p className='titleRelease'>Ubicación y Teléfonos Comité</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1TvprGBGROubf7q5GGFXx4mTSWQPximDx' target="blank"><Objecion /></a>
              <p className='titleRelease'>Objeción ante el TFCA</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1TcKJ7cPhUeAJ1ptCiouAE5Maee26bOec' target="blank"><Acuerdos /></a>
              <p className='titleRelease'>Acuerdos y CGT</p>
            </div>
            <div className='release' >
              <a href='https://drive.google.com/file/d/1KXE-4eJJeEfWagYBexe5AaaqLJM9qVUz' target="blank"><Asamblea /></a>
              <p className='titleRelease'>Convocatoria a Asamblea Nacional General Permanente Extraordinaria</p>
            </div>
          </article>
        </section>
      </main >
      <footer>
        <NavBarFooter />
      </footer>
      <GoToTop />
    </>
  )
}

export default Comunicados;