import '../styles/SectionHeadings.css';
import React from "react";
import { useNavigate } from 'react-router-dom';

//Releases
import { EsquelaSaraMontiel, AcuerdosFSTSE2024, ConvocatoriaCongreso2024, FormatoPonencias2024 } from '../images/ImagesHomeComunicados';

//Multimedia
import { ExcuAca24, SemSaludDia01, SemSaludDia02, SemSaludDia03, } from '../images/ImagesHome';

//PDF
import { LeyFederal, Constitucion, Condiciones2023, Estatutos2024 } from '../images/ImagesHome'

//Contact
import { Facebook, Instagram, Twitter, Mapa } from '../images/ImagesHome';

export const SectionReleases = () => {
  const navigate = useNavigate()
  const moveToReleases = () => {
    navigate("/comunicados")
  };

  return (
    <section className='container-section'>
      <p className="title-section">Comunicados</p>
      <hr />
      <article className='container-info'>

        <div className='container-mini'>

          <div className='imgSingle item1'>
            <a href='https://drive.google.com/file/d/1xdxnab7H3oooB0ZiEKkMjoI4sPLIMEaD' target="blank"><FormatoPonencias2024 /></a>
            <p className='title-image'><strong>Formato de Ponencia</strong></p>
          </div>
          <div className='imgSingle item2'>
            <a href='https://drive.google.com/file/d/1QNXffIGNCcqSYHUTtqHvaM1Nj0R2kTAA' target="blank"><ConvocatoriaCongreso2024 /></a>
            <p className='title-image'><strong>Convocatoria al XV Congreso Nacional Ordinario de representantes del Sindicato Nacional de Trabajadores de la Secretaría de Desarrollo Agrario, Territorial y Urbano</strong></p>
          </div>
          <div className='imgSingle item3'>
            <a href='https://drive.google.com/file/d/1rrWJTMTT84DAOrsHPptZQCumkRUMi2I6' target="blank"><AcuerdosFSTSE2024 /></a>
            <p className='title-image'><strong>Acuerdos signados entre la Secretaría de Hacienda y Crédito Público y la Federación de Sindicatos de Trabajadores al Servicio del Estado 2024</strong></p>
          </div>
          <div className='imgSingle item4'>
            <a href='https://drive.google.com/file/d/19jk4n4SJrgo_SdhOE41zGBsIPlIQvxZd' target="blank"><EsquelaSaraMontiel /></a>
            <p className='title-image'><strong></strong></p>
          </div>
        </div>

        <p className="go-to" onClick={moveToReleases}>Ver más</p>

      </article>
    </section >
  )
}
export const SectionMultimedia = () => {
  const navigate = useNavigate()
  const moveToMultimedia = () => {
    navigate("/multimedia")
  }

  return (
    <section className='container-section'>
      <p className='title-section'>Galería</p>
      <hr />
      <article className='container-info'>

        <div className='container-mini'>
          <div className='imgSingle item1' onClick={moveToMultimedia}><ExcuAca24 /><p className='title-image'><strong>Excursión al puerto de Acapulco en el Estado de Guerrero <br /> (del 14 al 18 de junio de 2024)</strong></p></div>
          <div className='imgSingle item2' ><SemSaludDia03 /><p className='title-image'><strong>Jornada de la Salud (Tercer Día) <br /> (15 de mayo de 2024)</strong></p></div>
          <div className='imgSingle item3' ><SemSaludDia02 /><p className='title-image'><strong>Jornada de la Salud (Segundo Día) <br /> (14 de mayo de 2024)</strong></p></div>
          <div className='imgSingle item4' ><SemSaludDia01 /><p className='title-image'><strong>Jornada de la Salud (Primer Día) <br /> (13 de mayo de 2024)</strong></p></div>
        </div>

        <p className="go-to" onClick={moveToMultimedia}>Ver más</p>

      </article>
    </section >
  )
}
export const SectionDocuments = () => {
  const navigate = useNavigate()
  const moveToDocuments = () => {
    navigate("/documentos")
  };

  return (
    <section className='container-section'>
      <p className="title-section">PDF</p>
      <hr />
      <div className='container-info'>

        <div className='container-mini'>
          <div className='imgSingle item1'><a href="https://drive.google.com/file/d/1Du06ml-Bh-khF19e22_S_kGlTGckhTqx" target='blank'><Estatutos2024 /></a><p className='title-image'><strong> Estatutos 2024</strong></p></div>
          <div className='imgSingle item2'><a href="https://drive.google.com/file/d/1qmop0kJXxKJ-QMpOLO7c66duE3q3SjIS" target='blank'><Condiciones2023 /></a><p className='title-image'><strong>Condiciones Generales de Trabajo 2023</strong></p></div>
          <div className='imgSingle item3'><a href="https://drive.google.com/file/d/1zsS87eYqsLa8_nTx9WUqhOv_-kqezfaK" target='blank'><Constitucion /></a><p className='title-image'><strong>Constitución Política de los Estados Unidos Mexicanos</strong></p></div>
          <div className='imgSingle item4'><a href="https://drive.google.com/file/d/12ueROVfkJhXL4QtH1ZnBnGGfYmP2lv1I" target='blank'><LeyFederal /></a><p className='title-image'><strong>Ley Federal de los Trabajadores al Servicio del Estado</strong></p></div>
        </div>

        <p className='go-to' onClick={moveToDocuments}>Ver más</p>

      </div>
    </section>
  )
}
export const SectionContact = () => {
  const navigate = useNavigate()
  const moveToContact = () => {
    navigate("/contacto")
  };

  return (
    <section className='container-section'>
      <p className="title-section">Contacto</p>
      <hr />
      <article className='container-info'>

        <div className='container-mini iconDisable'>
          <div className='imgSingle' >
            <a className='network' href='https://www.facebook.com/sindicato.nacional.sedatu' target="blank"><Facebook /></a>
          </div>
          <div className='imgSingle' >
            <a className='network' href='https://www.instagram.com/sindicato.nacional.sedatu/' target="blank"><Instagram /></a>
          </div>
          <div className='imgSingle'>
            <a className='network' href='https://twitter.com/SindNacSedatu' target='blank'><Twitter /></a>
          </div>
          <div className='imgSingle item4'>
            <a className='network' href='https://goo.gl/maps/wvn5eD9dTEH1VS4G8' target="blank"><Mapa /> </a>
          </div>
        </div>
        <p className='go-to' onClick={moveToContact}>Ver más</p>
      </article>
    </section>
  )
}

/*export const SectionDirectory = () => {
  const navigate = useNavigate()
  const moveToDirectory = () => {
    navigate("/directorio")
  };
  return (
    <section className='container-section'>
      <p className="title-section">Directorio</p>
      <hr />
      <div className='container-info'>

        <div className='container-mini'>
          <div className='imgSingle item1' ><Usuario /><p className='title-image'><strong>Gerardo Rodríguez Benítez</strong><br />Secretario General</p></div>
          <div className='imgSingle item2' ><Usuario /><p className='title-image'><strong>Antonio Padilla Coronado</strong><br />Secretario de Trabajo y Conflicos O.F.</p></div>
          <div className='imgSingle item3' ><Usuario /><p className='title-image'><strong>Martín Badillo Rojo</strong><br />Secretario de Trabajo y Conflicos O.C.</p></div>
          <div className='imgSingle item4' ><Usuario /><p className='title-image'><strong>Miguel Garcia Robledo</strong><br />Secretario de Organización</p></div>
        </div>

        <p className='go-to' onClick={moveToDirectory}>Ver más</p>

      </div>
    </section>
  )
}*/

/*export const SectionTransparency = () => {
  const navigate = useNavigate()
  const moveTotransparency = () => {
    navigate("/transparencia")
  };

  return (
    <section className='container-section'>
      <p className="title-section">Transparencia</p>
      <hr />
      <div className='container-info'>

        <div className='container-mini'>
          <div className='imgSingle item1'></div>
          <div className='imgSingle item2'></div>
          <div className='imgSingle item3'></div>
          <div className='imgSingle item3'></div>
        </div>

        <p className='go-to' onClick={moveTotransparency}>Ver más</p>

      </div>
    </section>
  )
}*/

