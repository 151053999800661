import '../styles/Images.css';

//Contacto
import mapa from '../assets/04_contacto/mapa.jpg';
import instagram from '../assets/04_contacto/instagram.png';
import twitter from '../assets/04_contacto/newX.png';
import facebook from '../assets/04_contacto/facebook.png';
import mailbox from '../assets/04_contacto/buzon.png';

//PDF
import condiciones2017 from '../assets/03_pdf/condiciones2017.avif';
import condiciones2023 from '../assets/03_pdf/condiciones2023.avif';
import constitucion from '../assets/03_pdf/constitucionPolitica.avif';
import estatutos from '../assets/03_pdf/estatutos2017.avif';
import etica from '../assets/03_pdf/etica2022.avif';
import inclusivo from '../assets/03_pdf/inclusivo.avif';
import leyFederal from '../assets/03_pdf/leyFederal.avif';
import leyGeneral from '../assets/03_pdf/leyGeneral.avif';
import issste from '../assets/03_pdf/leyIssste.avif';
import percepciones from '../assets/03_pdf/percepciones2023.avif';
import estatutos2024 from '../assets/03_pdf/estatutos_2024.avif'

//Galeria
import semsalud01dia from '../assets/02_galeria/54_dia01/5.avif';
import semsalud02dia from '../assets/02_galeria/55_dia02/2.avif';
import semsalud03dia from '../assets/02_galeria/56_dia03/5.avif';
import excuaca24 from '../assets/02_galeria/57_excur_aca_24/7.avif';

//Galeria
const SemSaludDia01 = () => {
  return (
    <img className='img-gallery' src={semsalud01dia} alt="" />
  )
}
const SemSaludDia02 = () => {
  return (
    <img className='img-gallery' src={semsalud02dia} alt="" />
  )
}
const SemSaludDia03 = () => {
  return (
    <img className='img-gallery' src={semsalud03dia} alt="" />
  )
}
const ExcuAca24 = () => {
  return (
    <img className='img-gallery' src={excuaca24} alt="" />
  )
}

//Contacto
const Mapa = () => {
  return (
    <img className='imgMap' src={mapa} alt="" />
  )
}
const Twitter = () => {
  return (
    <img className='imgIcon' src={twitter} alt="" />
  )
}
const Instagram = () => {
  return (
    <img className='imgIcon' src={instagram} alt="" />
  )
}
const Facebook = () => {
  return (
    <img className='imgIcon' src={facebook} alt="" />
  )
}
const Mailbox = () => {
  return (
    <img className='iconMail' src={mailbox} alt="" />
  )
}
//PDF
const Condiciones2017 = () => {
  return (
    <img className='img-home' src={condiciones2017} alt="" />
  )
}
const Condiciones2023 = () => {
  return (
    <img className='img-home' src={condiciones2023} alt="" />
  )
}
const Constitucion = () => {
  return (
    <img className='img-home' src={constitucion} alt="" />
  )
}
const Estatutos2017 = () => {
  return (
    <img className='img-home' src={estatutos} alt="" />
  )
}
const Etica = () => {
  return (
    <img className='img-home' src={etica} alt="" />
  )
}
const Inclusivo = () => {
  return (
    <img className='img-home' src={inclusivo} alt="" />
  )
}
const LeyFederal = () => {
  return (
    <img className='img-home' src={leyFederal} alt="" />
  )
}
const LeyGeneral = () => {
  return (
    <img className='img-home' src={leyGeneral} alt="" />
  )
}
const Issste = () => {
  return (
    <img className='img-home' src={issste} alt="" />
  )
}
const Percepciones = () => {
  return (
    <img className='img-home' src={percepciones} alt="" />
  )
}
const Estatutos2024 = () => {
  return (
    <img className='img-home' src={estatutos2024} alt="" />
  )
}

export {

  ExcuAca24,
  SemSaludDia03,
  SemSaludDia02,
  SemSaludDia01,

  Estatutos2024,
  Condiciones2017,
  Condiciones2023,
  Constitucion,
  Estatutos2017,
  Etica,
  Inclusivo,
  LeyFederal,
  LeyGeneral,
  Issste,
  Percepciones,
  Mailbox,
  Facebook,
  Instagram,
  Twitter,
  Mapa,
};